import React from "react";

function About() {
	return (
		<>
		<section id="about" className="content">
			<div className="grid grid--1">
				<div className="grid-wrap">
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/1.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/2.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/3.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/4.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/5.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/6.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/7.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/8.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/9.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/10.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/11.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/12.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/13.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/14.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/15.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/16.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/17.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/18.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/19.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/20.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/21.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/22.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/23.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/24.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/25.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/26.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/27.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/28.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/29.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/30.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/31.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/32.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/33.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/34.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/35.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/36.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/37.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/38.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/39.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/40.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/41.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/42.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/43.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/44.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/45.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/46.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/47.webp)"}}
						></div>
					</div>
					<div className="grid__item">
						<div
							className="grid__item-inner"
							style={{backgroundImage: "url(images/48.webp)"}}
						></div>
					</div>
				</div>
			</div>
			<h3 className="content__title content__title--right content__title--top">
				#transcendingrealities
			</h3>
		</section>
		</>
	);
}

export default About;